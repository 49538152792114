// login error

.displayError {
  display: block;
  text-align: center;
}

.hideError {
  display: none;
}

// login logo

.login-logo {
  background: transparent url("/br_logo_sm.png") no-repeat center;
  background-size: 90%;
  height: 150px;
  width: 150px;
  margin: auto;
}

//login background

.login-background {
  background: -moz-linear-gradient(top, #5ec6c2 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #5ec6c2 0%, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #5ec6c2 0%, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  height: 40%;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.login-background-footer {
  margin: 0;
  text-align: center;
  background: transparent url("/login_footer.jpg") no-repeat bottom;
  background-size: cover;
  background-position-y: 100px;
  height: 40%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.login-line {
  border-bottom: 1px solid #66cdc9;
  border-top: 1px solid #66cdc9;
}

//login form

.form-signin {
  max-width: 400px;
  margin: auto;
}

.center {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 10px;
}

.login-button-holder {
  text-align: center;
}

.login-button {
  border-radius: 15px !important;
  background-color: $best-ring-light-gray;
  max-width: 350px;
  margin: auto;
}

.login-button,
.login-button:hover,
.login-button:active {
  color: white !important;
}

.login-button:hover,
.login-button:active {
  background-color: $best-ring-dark-gray;
}

.form-signin input[type="password"] {
  margin-top: 10px;
}

.form-signin input[type="text"],
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border: none;
  outline: none;
  width: 100%;
  height: 30px;
  text-align: center;
  background-color: transparent;
  color: #757575;
  font-size: 16px;
  font-weight: bold;
}

//set properties for ms
.form-signin input[type="text"]:-ms-input-placeholder,
.form-signin input[type="password"]:-ms-input-placeholder {
  color: #757575;
  font-size: 16px;
  font-weight: bold;
  opacity: 100;
}

//set properties for firefox 18-
.form-signin input[type="text"]:-moz-placeholder,
.form-signin input[type="password"]:-moz-placeholder {
  color: #757575;
  font-size: 16px;
  opacity: 100;
  font-weight: bold;
}

//set properties for chrome
.form-signin input[type="text"]::-webkit-input-placeholder,
.form-signin input[type="password"]::-webkit-input-placeholder {
  color: #757575;
  font-size: 16px;
  opacity: 100;
  font-weight: bold;
}

//set properties for firefox 19+
.form-signin input[type="text"]::-moz-placeholder,
.form-signin input[type="password"]::-moz-placeholder {
  color: #757575;
  font-size: 16px;
  opacity: 100;
}

@-webkit-keyframes autofill {
  to {
    color: #757575;
    font-size: 16px;
    background: transparent;
    font-weight: bold;
  }
}

.form-signin input[type="text"]:-webkit-autofill,
.form-signin input[type="password"]:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

.version {
  position: fixed;
  bottom: 0px;
  font-size: 8pt;
  height: 20px;
}

.navbar-version {
  text-align: right;
  padding-right: 30px;
  width: 300px;
  color: #ffffff;
}

.login-version {
  text-align: center;
  width: 100%;
  color: #1c1c1c;
  z-index: 99;
}
