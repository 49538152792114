.editRow {
  text-align: center;
  width: 3%;
}

.deleteRow {
  text-align: center;
  width: 1%;
}

.comboSelectionProductRow {
  text-align: left;
  width: 2%;
}

.changePasswordRow {
  text-align: center;
  width: 5%;
}

.centerContent {
  text-align: center;
}

.stateRow {
  min-width: 120px;
}
