.userProfiles {
  display: block !important;
  padding-top: 15px;
  padding-bottom: 15px;
}
.userProfiles li {
  list-style-type: none;
}

.center_buttons {
  margin-top: 40px !important;
  margin-left: 150px;
}

.idEditRow {
  width: 110px;
}