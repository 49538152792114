.report_datepicker > input {

}

.deductions-category-cell {
  width: 75%;
}

.deductions-total-cell {
  width: 25%;
}

.total-deductions {
  position: relative;
  bottom: 15px;
  left: 5px;
}

.total-deductions-label {
  width: 75%;
}

.non-cash-category-cell {
  width: 25%;
}

.non-cash-quantity-cell {
  width: 25%;
  min-width: 200px;
}

.non-cash-total-cell {
  width: 25%;
  min-width: 150px;
}

.total-non-cash {
  position: relative;
  bottom: 15px;
  left: 5px;
}

.total-non-cash-label {
  width: 33%;
}

.quantity-non-cash-label {
  width: 33%;
}

.sales-by-category-cell {
  width: 12%;
}

.sales-by-product-cell {
  width: 12%;
}

.date-cell {
  width: 25%;
}

.total-sales-by-category {
  position: relative;
  bottom: 15px;
  left: 5px;
}

.total-sales-by-category-label {
  font-weight: bold;
  width: 12.5%;
  float: left;
}

.cash-deposit-quantity-cell, payment-report-quantity-cell, payment-report-category-cell {
  width: 12%;
}

.inner-table-wrapper {
  overflow-x: scroll;
}

.btn.btn-box-tool.selectable {
  color: #97a0b3;
}

.btn.btn-box-tool.selectable.selected {
  color: #00a9a4;
}
.box.box-primary .table {
  margin-bottom: 0;
}

.total-row {
  font-weight: bold;
}
