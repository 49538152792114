body {
  font-family: Arial, Verdana, sans-serif !important;
  font-size: 14px !important;
  font-weight: 300;
}

label {
  vertical-align: middle;
}

.content.container-fluid {
  padding: 0;
}

.category-grid {
  position: relative;
  min-height: 500px !important;
  text-align: center;
}

.full-container {
  width: 100% !important;
  margin: 0 !important;
  padding-left: 15px !important;
  padding-right: 20px !important;
}

.row{
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.row-height {
  padding-top: 20px;
  padding-bottom: 20px;
}

.fcs-jumbotron {
  padding-top: 1px;
  padding-left: 90px;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  border-radius: 4px;
  background: transparent;
  transition: all 0.3s ease;
}

::-webkit-scrollbar:hover {
  background: #c6c7ca;
}

::-webkit-scrollbar-thumb {
  background: #a6a6a8;
  border-radius: 4px;
}

.modals-button {
  float: right;
  margin: 0 5px 0 5px;
}

.left-align > div {
  padding-left: 0;
}

.icon {
  cursor: pointer;
  color: #333333;
}

.ember-power-select-dropdown {
  font-size: 95% !important;
}

.ember-power-select-trigger {
  text-align: left;
}

.table-summary, .table-nav {
  transform: translateY(40%);
}

.date-picker__wrapper {
  width: 100%;
}

.date-picker__button {
  padding: 2px;
  border: 1px solid gray;
  border-radius: 4px;
  width: 47.5%;
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: none !important;
}

@media(max-width: 992px) {
  .col-sm-pad {
    padding-bottom: 16px;
  }
}
@media(max-width: 1200px) {
  .col-md-pad {
    padding-bottom: 16px;
  }
}
@media(max-width: 768px) {
  .col-xs-pad {
    padding-bottom: 16px;
  }
}

/*
  Color Overrides
 */

.box.box-primary {
  border-top-color: #00a9a4;
}
.btn.btn-success {
  color: #fff;
  background-color: #00a9a4;
  border-color: #1eb3af;
}
.btn.btn-primary {
  color: #fff;
  background-color: #00a9a4;
  border-color: #1eb3af;
}

.btn.btn-success:hover,
.btn.btn-success:focus,
.btn.btn-success.focus,
.btn.btn-success:active,
.btn.btn-success.active:hover,
.btn.btn-success.active:focus,
.btn.btn-success.active.focus,
.open > .btn.btn-success.dropdown-toggle:hover,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #49c2be !important;
  border-color: #76d1ce !important;
}

.btn.btn-modal {
  color: #fff;
  background-color: rgba(62, 63, 132, 0.47);
  border-color: rgba(62, 63, 132, 0.47);
}

.btn.btn-modal:hover,
.btn.btn-modal:focus,
.btn.btn-modal.focus,
.btn.btn-modal:active,
.btn.btn-modal.active:hover,
.btn.btn-modal.active:focus,
.btn.btn-modal.active.focus,
.open > .btn.btn-success.dropdown-toggle:hover,
.open > .btn.btn-success.dropdown-toggle:focus,
.open > .btn.btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: rgba(62, 63, 132, 0.76) !important;
  border-color: rgba(62, 63, 132, 0.76) !important;
}
.btn.btn-primary:active:hover, .btn.btn-primary:active:focus, .btn.btn-primary:active.focus, .btn.btn-primary.active:hover, .btn.btn-primary.active:focus, .btn.btn-primary.active.focus, .open > .btn.btn-primary.dropdown-toggle:hover, .open > .btn.btn-primary.dropdown-toggle:focus, .open > .btn.btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: rgba(62, 63, 132, 0.64) !important;
  border-color: rgba(62, 63, 132, 0.62) !important;
}


.info-bubble {
  color: #00a9a4;
}
.info-bubble:hover {
  color: #76d1ce;
}

html, body, body > .ember-view:not(.liquid-target-container), .setupHeight.ember-view {
  height: auto;
}

.main-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
}
.content.container-fluid {
  margin-top: 50px;
}

.ember-tooltip {
  z-index: 999;
}
.list-group-hover .list-group-item:hover {
    background-color: #c2f5f0;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: x-small;
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid silver;
}
.separator::before {
    margin-right: 1em;
}
.separator::after {
    margin-left: 1em;
}

.ember-power-select-trigger-multiple-input {
  padding-left: 4px !important;
}

.footer{
    position: absolute;
    bottom:0;
    color: #ffff;
    clear:both;
    width:100%;
    height:5%;
}

.branding-row {
  display: flex;
  align-items: center;
}
.branding-wrapper {
  .branding-preview {
    width: 640px;
    height: 80px;
    background-size: 100%;
  }
  .branding-name {
    color: #d3d3d3;
  }
}

