.companyBlock {
  max-height: 600px;
  overflow-y: scroll;
  margin-bottom: 10px;
}

.companyRow {
  padding-top: 8px;
  padding-bottom: 8px;
}

.custom-descriptor {
  padding-top: 6px;
}

.companyRow:nth-child(even) {
  background: #e7f3f7
}

.companyRow:nth-child(odd) {
  background: #fbfbfb
}

.remove-icon {
  float: right;
}

.remove-icon i {
  cursor: pointer;
}

.bin-box {
  text-wrap: normal;
  min-width: 200px;
  min-height: 200px;
  overflow-scrolling: auto;
}
.img-fluid {
  max-width: 768px;
  max-height: 90px;
}

.cashless-logo-preview {
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 0;
  border: 1px solid #d3d3d3;
}

.cashless-background-preview {
  width: 192px;
  height: 108px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 0;
  border: 1px solid #d3d3d3;
}

.image-upload-container {
  display: flex;
  justify-content: left;
  align-items: left;
}
.upload-wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
}
.button-desc-note {
  font-size: 12px;
  color: #6c757d;
  margin-top: 8px;
}
.event-image-previewer-wrapper {
  justify-content: left;
}
