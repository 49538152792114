.row-separation {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid #cccccc;
}
.no-separator {
  border: none;
}

.row-separation-without-border {
  padding-top: 10px;
  padding-bottom: 10px;
}

.row-separation-padding-top {
  padding-top: 10px;
}

.extra-options {
  background-color: #f7f1f1;
  padding-right: 0;
  padding-left: 0;
}

.date-label {
  display: inline-block;
  line-height: 35px;
}

.payment-options > ul {
  height: 60px;
}

.payment-options > ul > li {
  list-style-type: none;
  float: left;
  width: 30%;
}

.payment-options > ul > li > label {
  width: 100%;
}

.card-type-options > ul {
  height: 60px;
}

.card-type-options > ul > li {
  list-style-type: none;
  float: left;
  width: 50%;
}

.card-type-options > ul > li > label {
  width: 100%;
}

.order-details {
  padding-top: 20px;
  padding-bottom: 20px;
}

.order-table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  color: #555555;
  width: 100%;
  padding: 10px;
}

.order-table > tr > td, .order-table > thead > th {
  border: 1px solid #ddd;
  padding: 5px;
}

.modal-position {
  margin-top: -440px !important;
}

.order-details-spinner {
  text-align: center;
  padding-top: 5%;
  padding-bottom: 5%;
}

.search-order-status {
  top: 4px;
}

.email-label {
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.panel.panel-order-search {
  background: none;
  margin: 0;
  border: none;
}
.panel-order-search .panel-body {
  padding: 0;
}
.panel-order-search .box {
  background-color: #f7f1f1;
}
.panel-order-search .control-label {
  line-height: 26px;
  margin-bottom: 0;
  white-space:nowrap;
}

.form-control.form-control-search {
  height: 26px;
  padding-left: 2px;
}

.multiselect-checkboxes {
  padding-left: 16px;
}
