.navbar {
  margin-bottom: 0 !important;
}

#wrapper {
  padding-top: 90px;
  padding-left: 70px;
  transition: all .4s ease 0s;
  height: 100%
}

#sidebar-wrapper {
  margin-left: -150px;
  left: -80px;
  width: 300px;
  background: $best-ring-light-gray;
  position: fixed;
  height: 100%;
  z-index: 10000;
  transition: all .4s ease 0s;
}

#page-content-wrapper {
  padding-left: 0;
  margin-left: 0;
  padding-top: 20px;
  width: 100%;
  height: auto;
}
#wrapper.active {
  padding-left: 300px;
}
#wrapper.active #sidebar-wrapper {
  left: 300px;
  margin-left: -300px;
}

#sidebar_menu li a, .sidebar-nav li a {
  color: $navbar-font-color;
  display: block;
  float: left;
  text-decoration: none;
  width: 300px;
  background: $best-ring-light-gray;
  -webkit-transition: background .5s;
  -moz-transition: background .5s;
  -o-transition: background .5s;
  -ms-transition: background .5s;
  transition: background .5s;
}

.sidebar_name {
  padding-top: 25px;
  color: $navbar-font-color;
  opacity: .7;
}

//navbar brand
.sidebar-nav {
  display: block;
  float: left;
  width: 300px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-nav li {
  line-height: 40px;
  text-indent: 20px;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  line-height: 60px;
  font-size: 18px;
}

.sidebar-nav > .sidebar-brand a {
  color: $navbar-font-color;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: $navbar-font-color;
  background: none;
}

//menu items
.sidebar-nav-menu {
  display: block;
  float: left;
  width: 300px;
  list-style: none;
  margin: 0;
  padding: 0;
  text-indent: 15px;
}

.sidebar-nav-menu li {
  line-height: 40px;
}

.sidebar-nav-menu li a {
  color: $navbar-font-color;
  display: block;
  text-decoration: none;
}

.sidebar-nav-menu li a:hover {
  color: $navbar-font-color;
  background: $best-ring-dark-gray;
  text-decoration: none;
}

.sidebar-nav-menu li a:active,
.sidebar-nav-menu li a:focus {
  text-decoration: none;
}

.sidebar-menu-text {
  display: inline-block;
  width: 75%;
}

.menu-items-icon {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar-submenu {
  padding-left: 0;
}

.sidebar-submenu li {
  text-indent: 40px !important;
}

.sidebar-nav-menu ul, .sidebar-nav-menu li {
  list-style-type: none;
}

.sidebar-nav-menu ul li a.active {
  color: $navbar-font-color;
  background: $best-ring-dark-gray;
}

#main_icon
{
  float:right;
  padding-right: 30px;
  padding-top:20px;
}
.sub_icon
{
  float:right;
  padding-right: 30px;
  padding-top:10px;
}
.content-header {
  height: 65px;
  line-height: 65px;
}

.content-header h1 {
  margin: 0;
  margin-left: 20px;
  line-height: 65px;
  display: inline-block;
}

.navbar-elements > p > a,
.navbar-nav > li > a {
  color: $navbar-header-font-color !important;
}

.navbar-elements > p > a {
  text-decoration: underline;
}

.navbar-elements > p > a:hover,
.navbar-nav > li > a:hover {
  color: $best-ring-dark-gray !important;
}

//css adjustments for different screen sizes

@media (min-width: 768px) {
  .navbar-header {
    width: 280px;
  }
  .navbar-elements, .navbar-elements > li {
    height: 90px;
  }

  .navbar-elements > p, .navbar-elements > li > a {
    line-height: 60px !important;
  }
}

@media (max-width: 767px) {
  .nav-logo {
  }

  #wrapper {
    padding-left: 70px;
    transition: all .4s ease 0s;
  }
  #sidebar-wrapper {
    left: 70px;
  }
  #wrapper.active {
    padding-left: 150px;
  }
  #wrapper.active #sidebar-wrapper {
    left: 150px;
    width: 300px;
    transition: all .4s ease 0s;
  }
}

@media (max-width: 767px) {
  .skin-black .main-header .logo, .skin-black .main-header .logo:hover {
    background-color: #ffffff;
    border-bottom: 1px solid #d2d6de;
  }
}
.logo-mobile {
  text-align: center;
  display:flex;
  justify-content:center;
  display: none;
}
@media (max-width: 767px) {
  .main-header .logo {
    display: none;
  }
  .main-sidebar {
    padding-top: 50px;
  }
  .logo-mobile {
    display: block;
  }
}
.skin-blue > .ember-view {
  background-color: #222d32;
}
.main-header .sidebar-toggle {
  padding: 15.5px 21.5px;
}
.sidebar-toggle {
  text-decoration: none !important;
}

.user-header a, .user-header i {
  color: white;
}
.user-header a:hover, .user-header i:hover {
  color: #76d1ce;
  text-decoration: none;
}

.user-menu-link a {
  text-align: center;
}

@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.user-menu-link a {
    color: #000;
  }
}
