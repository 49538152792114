.fcs-modal-overlay {
  z-index: 20000;
}

.ember-modal-dialog[style] {
  left: 0 !important;
  top: 0 !important;
  z-index: 50 !important;
  width: 97.5% !important;
  margin: 2% !important;
}
.modal-dialog {
  width: 97.5% !important;
  left: 0 !important;
  top: 0 !important;
  margin: 2% !important;
  z-index: 50 !important;
}

.ember-modal-overlay {
  overflow-y: scroll;
}
