.multiselect-panels {
  margin-bottom: 15px;
}

.multiselect-panels-column {
  min-height: 1px;
  border: 1px solid #b19f9f;
  padding: 0;
}

.multiselect-panels-options {
  height: 140px;
  margin-top: 5px;
  overflow: auto;
}

.multiselect-panels-item {
  cursor: pointer;
  border-bottom: 1px solid #d2d2d2;
  padding: 2px 5px;
  display: block;
}

.multiselect-panels-item:hover {
  background: #75a6ff;
  color: #fff;
}
