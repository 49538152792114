.menuBuilderCheckBox {
  height: 20px;
  width: 20px;
  border: 0;
  -webkit-box-shadow: inset 0 0 0;
}

.menuTaxRateInput{
  width: 50%;
}

.printOptionsCheckboxes > ul {
  height: 80px;
}

.printOptionsCheckboxes > ul > li {
  list-style-type: none;
  float: left;
  width: 34%;
}

.cashDrawerCheckboxes > ul {
  height: 80px;
}

.cashDrawerCheckboxes > ul > li {
  list-style-type: none;
  float: left;
  width: 34%;
}

.uploadButton label {
  background: #34495e;
  padding: 10px;
  color: white;
  border-radius: 5px;
}

.logo-wrapper {
  width: 35%;
  text-align: center;
  background-color: #f3f3f3;
}

.promotion-banner-wrapper {
  width: 150px;
  height: 150px;
  padding-top:20px;
}

.camera-icon {
  font-size: 40px;
  color: #34495e;
  padding: 10px;
  text-align: center;
  width: 200px;
  height: 200px;
}

.promotion-image-camera-icon {
  font-size: 40px;
  color: #34495e;
  padding: 10px;
  text-align: center;
  width: 50px;
  height: 50px;
}

.logo-preview {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  border-radius: 100%;
  border: 1px solid #d3d3d3;
}

.product-image-preview {
  width: 300px;
  height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
  border: 1px solid #d3d3d3;
}

.promotion-banner-preview {
  width: 1000px;
  height: 130px;
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #d3d3d3;
}

.logo-name {
  padding: 8px;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  color: #d3d3d3;
  margin-top: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file-name {
  padding: 8px;
  border: 1px solid #34495e;
  border-radius: 5px;
  color: #34495e;
  margin-top: 12px;
}

.logo-name label:hover {
  border: 1px solid #34495e;
  color: #34495e;
}

.fa {
  position: relative;
  top: 35%;
}

.categoryTable {
  margin: 15px 45px 15px 45px;
}

.pageTable {
  margin: 0 45px 15px 15px;
}

.pageContent {
  margin: 0 30px 15px 0;
  font-size: 90%;
}

.row-selected {
  background-color: rgba(65, 176, 249, 0.26) !important;
}

.product-table > thead > tr > th {
  text-align: center;
}

.locationsContainer {
  margin-top: 15px;
  min-height: 475px;
  overflow-y: hidden;
}

//product catalog table
.productCatalogTable {
  height: 475px;
}

.product-catalog-spinner {
  height: 475px;
  text-align: center;
}

.productCatalogTable > table > tbody,
.productCatalogTable > table > thead > tr {
  display: block;
}

.productCatalogTable > table > tbody {
  height: 400px;
  overflow-y: auto;
}

.productCatalogTable > table > tbody > tr > td,
.productCatalogTable > table > thead > tr > th {
  width: 290px;
  text-align: center;
}

.productCatalogTable > table > thead > tr > th:last-child {
  width: 300px;
}

//attributeSet table
.attributeSetTable {
  max-height: 400px;
  height: auto;
}

.attributeSetTable > table > tbody,
.attributeSetTable > table > thead > tr {
  display: block;
}

.attributeSetTable > table > tbody {
  height: auto;
  overflow-y: auto;
}

.attributeSetTable > table > tbody > tr > td,
.attributeSetTable > table > thead > tr > th {
  width: 290px;
  text-align: center;
}

.attributeSetTable > table > thead > tr > th:last-child {
  width: 300px;
}

//topping table
.toppingsTable {
  height: 475px;
}

.toppingsTable > table > tbody,
.toppingsTable > table > thead > tr {
  display: block;
}

.toppingsTable > table > tbody {
  height: 400px;
  overflow-y: auto;
}

.toppingsTable > table > tbody > tr > td,
.toppingsTable > table > thead > tr > th {
  width: 430px;
  text-align: center;
}

.toppingsTable > table > thead > tr > th:last-child {
  width: 450px;
}

//productType table
.productTypeTable-tax-input {
  width: 25%;
}

.tooltipHelp {
  font-family: Arial !important;
  max-height: 80px;
  max-width: 300px;
}

.sortObject {
  margin: 10px;
  width: 150px;
  height: 100px;
  text-align: left;
  padding: 10px;
  color: black;
  border-radius: 4px;
  border: 1px solid #76bfe0;
}

.sortObject > #productName {
  font-size: 90%;
  padding-bottom: 5px;
}

.u-pullLeft {
  float: left;
}

.page-container {
  padding-left: 0;
  height: 500px;
}

.list-view-container {
  height: 462px;
  width: available;
  overflow-y: scroll;
}

.view-container {
  width: 600px;
  height: 450px;
  overflow-y: scroll;
}

.category-group {
  background-color: #1d9bd4 !important;
  border-color: #1d9bd4 !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.category-group:active, .category-group.active {
  background-color: #1d82b8 !important;
  border-color: #1d82b8 !important;
}

.left-button {
  padding: 0 0px 0 3px !important;
  height: 60px;
  width: 25px;
}

.right-button {
  height: 60px;
  padding: 0 3px 0 0 !important;
  width: 25px;
}

.category-button {
  width: 120px;
  height: 60px;
}

.category-button > #categoryName {
  font-size: 90%;
  white-space: normal;
  width: 100%;
  display: block;
  word-break: break-all;
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
}

.category-button > #pageName {
  font-size: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  border-top: 1px solid white;
}

.category-button > #categoryIcon {
  display: inline-block;
}

.product-cell {
  white-space: nowrap;
  width: 50%;
  word-break: break-all;
  text-align: center;
}

.filler-cell {
  min-width: 100px;
  max-width: 150px;
  text-align: center;
}

.tooltip-error {
  font-family: Arial !important;
  background-color: #e37f7f;
  color: #fff;
  max-height: 70px !important;
  z-index: 20001;
}

.tooltip-info {
  font-family: Arial !important;
  background-color: #4b3695;
  color: #fff;
  max-height: 70px !important;
  z-index: 20001;
}
