$ember-basic-dropdown-content-background-color: #FFF !default;
$ember-basic-dropdown-content-z-index: 1000 !default;
$ember-basic-dropdown-overlay-background: rgba(0,0,0, 0.5) !default;
$ember-basic-dropdown-overlay-pointer-events: none !default;

.ember-basic-dropdown {
  position: relative;
}
.ember-basic-dropdown, .ember-basic-dropdown-content, .ember-basic-dropdown-content * {
  box-sizing: border-box;
}
.ember-basic-dropdown-content {
  position: absolute;
  width: auto;
  z-index: $ember-basic-dropdown-content-z-index;
  background-color: $ember-basic-dropdown-content-background-color;
}
.ember-basic-dropdown-content--left { left: 0; }
.ember-basic-dropdown-content--right { right: 0; }


.ember-basic-dropdown-overlay {
  position: fixed;
  background: $ember-basic-dropdown-overlay-background;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  pointer-events: $ember-basic-dropdown-overlay-pointer-events;
}
