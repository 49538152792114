.breadcrumb {
  margin-bottom: 5px;
  font-size: 16px;
  color: #adadad;
}

.breadcrumb li a {
  cursor: pointer;
}

.breadcrumb > .active {
  color: #122b40;
}
